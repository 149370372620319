export default function ({ redirect, store }) {
  const logoutCallback = (e) => {
    if (e.key === 'gleaninLogout' && e.newValue === 'true') {
      store.$auth.logout('local').then(() => {
        store.commit('resetState')
        window.localStorage.removeItem('gleaninLogout')

        // Delete all auth cookies from both specific subdomain and top-level domain
        const cookies = document.cookie.split(';')
        cookies.forEach((cookie) => {
          const cookieName = cookie.split('=')[0].trim()
          if (cookieName.startsWith('auth.')) {
            // Delete cookie from specific subdomain
            // This is just while we switch to the support X-domain login.
            // Without this, the subddomain ccokie remains & keeps you logged in.
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`
          }
        })

        return redirect('/login')
      })
    }
  }

  window.addEventListener('storage', logoutCallback)
}
